import { graphql } from "gatsby";
import React, { Component } from "react";
import styled from "styled-components";
import { H1, H4, HR, P } from "@upsolve/ui";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import Article from "../components/Layout/Article.div";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import richTextNodeToComponent from "../components/Contentful/richTextNodeToComponent";
import BreadcrumbNav from "../components/Layout/BreadcrumbNav";
import RelatedArticles from "../components/Programmatic/RelatedArticles";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";

const contentGroup = CONTENT_GROUPS.LEGAL_DEFINITION;
const templateProps = {
  pageComponentName: "GlossaryDefinition",
  pageComponentVersion: 1,
};
const trackingPageContext = {
  ...templateProps,
  contentGroup,
  contentLocalized: false,
};

/**
 * GlossaryDefinition
 * - v0: Getting us by with some simple page/link
 * - v1: Definitions page follows article template, has rich text content, has related article links
 *
 * @component
 * @version 1
 */
class GlossaryDefinition extends Component {
  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    const { content, definition, slug, term, relatedArticles } = this.props.data.contentfulGlossaryDefinition;

    return (
      <TopLevelLayout>
        <SEO
          contentPath={`/learn/${slug}/`}
          title={`${term} | Definition of ${term}`}
          description={`${term} definition: ${definition.childMarkdownRemark.excerpt}`}
        />
        <MainHeader trackingPageContext={trackingPageContext} />
        <DefinitionArticle>
          <BreadcrumbNav />
          <div className="article__body">
            <div className="article__content">
              <div className="content-hero">
                <header className="content-hero__header" id="top">
                  <H1 as="h1">{term}</H1>
                </header>
              </div>
              <div className="content-body full-width">
                <article className="content-body__container">
                  <div className="content-body__lead">
                    <div className="summary">
                      <b>Definition</b>
                      <span dangerouslySetInnerHTML={{ __html: definition.childMarkdownRemark.html }} />
                    </div>
                  </div>
                  {content && content.json && (
                    <>
                      <br />
                      <section>
                        <H4 as="h2">Learn more about "{term}"</H4>
                        <HR />
                        {richTextNodeToComponent(content.json)}
                      </section>
                    </>
                  )}
                  {relatedArticles && (
                    <RelatedArticles articles={relatedArticles} includeHardcoded={false} limit={16} />
                  )}
                </article>
              </div>
            </div>
          </div>
        </DefinitionArticle>
        <MainFooter />
      </TopLevelLayout>
    );
  }
}

/* eslint no-undef: "off"*/
export const pageQuery = graphql`
  query GlossaryDefinitionBySlug($slug: String!) {
    contentfulGlossaryDefinition(slug: { eq: $slug }) {
      id
      slug
      term
      definition {
        childMarkdownRemark {
          excerpt(pruneLength: 10000)
          html
        }
      }
      content {
        json
      }
      relatedArticles {
        slug
        title
      }
      createdAt
    }
  }
`;

export const DefinitionArticle = styled(Article)`
  max-width: 800px;
  .article__content .content-body__container {
    width: 100%;
  }
`;

export default GlossaryDefinition;
