import styled, { css } from "styled-components";
import { ACSS } from "@upsolve/ui";
import { BreadCrumbCSS } from "./BreadcrumbNav";

const Article = styled.main.attrs((props) => ({
  id: "article",
}))`
  width: 100%;
  max-width: 1140px;
  padding-left: 35px;
  margin: 4em auto 2em;
  display: flex;
  flex-direction: column;
  overflow: visible;

  @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
    padding-left: 0;
    max-width: calc(100vw - 35px);
  }
  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    padding-left: 0;
    margin: 1em auto;
    max-width: 100%;
  }

  .article__breadcrumb {
    ${BreadCrumbCSS}
  }

  .article__content {
    word-break: break-word;

    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      width: 100%;
    }

    div.cover-image {
      height: 300px;
      width: 100%;
      margin: 0 auto 2em;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content-hero {
      display: flex;
      flex-direction: row;
      align-items: space-between;
      margin: 0 0 1em;
      //width: calc(100% + 4em);
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        margin: 0;
        width: 100%;
      }
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        flex-direction: column;
        align-items: initial;
      }
    }
    .content-hero__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      ol,
      ul {
        padding: 0;
        margin: 0.5em 0;
      }
      label {
        margin-right: 0.5em;
      }
      h1 {
        background: ${(props) => props.theme.colors.white[900]};
        color: ${(props) => props.theme.colors.gray[400]};
        padding: 0.25em 1em 0.25em 0.25em;
        margin: 0 -0.25em;
      }
    }

    .content-author {
      display: flex;
      align-items: center;
      background: ${(props) => props.theme.colors.white["900"]};
      padding: 0.1em 0.5em;
      margin: -0.1em -0.5em;
      & > div {
        margin: 1.25em 0;
      }
      .author-image,
      img {
        height: 48px;
        width: 48px;
        min-height: 48px;
        min-width: 48px;
        border-radius: 36px;
      }
      & > p {
        margin: 1em 0.75em;
        font-size: 14px;
      }
      small {
        color: ${(props) => props.theme.colors.gray["900"]};
      }
      &__images {
        display: flex;
        padding-right: 4px;
        & > * {
          margin: 0 -4px;
        }
      }
    }
    .content-insert {
      a {
        ${ACSS}
      }
    }

    .content-video {
      max-width: 100%;
      height: 360px;
      margin: 0 auto;
      padding: 1em 0;
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        width: 540px;
        height: 270px;
      }
    }

    .content-body {
      display: flex;
      flex-wrap: wrap-reverse;
      float: left;
      padding: 0;
      margin-top: 0.5em;
      &.full-width {
        width: 100%;
        max-width: 100%;
      }
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        padding-top: 0;
        margin-top: 0;
      }
      section,
      div.section {
        display: block;
        padding-bottom: 1em;
        margin-bottom: 1.5em;
        border-bottom: 1px solid ${(props) => props.theme.colors.white["500"]};
        &:first-of-type {
          & > *:first-of-type {
            margin-top: 0;
          }
        }
        &:last-of-type {
          border-bottom: none;
        }
        p {
          img {
            min-height: 240px;
            min-width: 240px;
            height: auto;
            width: auto;
            max-height: 400px;
            max-width: 100%;
            display: block;
            margin: 0 auto;
          }
        }
        & > img {
          max-height: 360px;
          margin: 0 auto;
          max-width: 100%;
        }
        div.image-caption {
          width: 100%;
          text-align: center;
          small {
            color: ${(props) => props.theme.colors.gray[900]};
          }
        }
        @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
          padding-bottom: 1.5em;
          margin-bottom: 1.5em;
        }
      }
      img {
        max-width: 100%;
      }
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        padding: 1em 0;
        width: 100%;
      }
    }

    .content-body__container {
      width: 66%;
      @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
        width: 75%;
        margin: 0 auto;
      }
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        width: 100%;
      }
    }

    .content-body__lead {
      width: 100%;
      & > div,
      & > section,
      & > div.section {
        width: 100%;
      }
      .summary {
        padding: 0 0 1em;
        margin: 0 auto 0.5em;
        border: 2px solid ${(props) => props.theme.colors.white["500"]};
        background: ${(props) => props.theme.colors.white["900"]};
        b {
          font-size: 18px;
          font-weight: 500;
          background: ${(props) => props.theme.colors.white["900"]};
          padding: 0.25em 1em;
          margin: 0 1em;
          position: relative;
          top: -0.8em;
        }
        p {
          padding: 0 2em;
          margin: 1em 0 1.5em;
        }
      }
    }

    .content-body__nav {
      list-style-type: disc;
      color: ${(props) => props.theme.colors.white["300"]};
      li {
        margin-left: 0;
        padding-left: 0;
        & > a {
          color: ${(props) => props.theme.colors.brand["500"]};
        }
      }
    }

    .author-bios {
      margin-bottom: 2em;
      .written-by {
        width: 100%;
        margin: 0.5em 0 0.25em;
        text-align: center;
        small {
          color: ${(props) => props.theme.colors.gray[700]};
          font-weight: 500;
        }
      }
      .team {
        text-align: center;
        background: ${(props) => props.theme.colors.white[800]};
        padding: 0.75em 1em;
        box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
        &,
        a {
          ${ACSS}
          font-weight: 500;
          font-size: 13px;
        }
      }
    }

    ul {
      list-style-type: disc;
    }
    ul,
    ol {
      padding: 0 1em;
    }
    li {
      margin: 0 0.25em 0.5em;
      & > p {
        margin: 0;
      }
    }
    hr {
      margin: 1em 0;
      border: 1px solid ${(props) => props.theme.colors.white["700"]};
    }
    h1 {
      margin: 0;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0.5em 0 0.25em;
      line-height: 125%;
    }
    iframe {
      border: none;
      outline: none;
      max-width: 100%;
      margin: 0 auto;
    }
    p,
    li {
      a {
        ${ACSS}
      }
    }
    table {
      margin: 0.75em 0;
    }
  }

  .related-content {
    & > div,
    section {
      margin: 1em 0;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      margin: 1em 1.5em;
    }
  }

  aside {
    float: right;
    width: 33%;
    color: ${(props) => props.theme.colors.gray["500"]};
    .sidebar-hero__cta {
      width: 100%;
      padding-left: 1.5em;
      position: sticky;
      top: 4em;
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        margin-left: 0;
      }
    }
    h5 {
      border-bottom: 1px solid ${(props) => props.theme.colors.white["500"]};
    }
    & > div,
    & > section,
    & > div.section {
      display: flex;
      flex-direction: column;
      margin-bottom: 2em;
    }
    p,
    li > a {
      font-size: 14px;
    }
    p > a {
      ${ACSS}
    }
    ul {
      margin-top: 1em;
      list-style: disc;
      color: ${(props) => props.theme.colors.white["500"]};
      li {
        margin-left: 1em;
        margin-bottom: 0.1em;
        & > a {
          color: ${(props) => props.theme.colors.brand["500"]};
        }
      }
    }

    // Sidebar (really just the CTA)
    @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
      width: 100%;
      .sidebar-hero__cta {
        padding-left: 0;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    .article__breadcrumb {
      margin: 0 1.5em;
      ol {
        margin: 0;
        font-size: 14px;
      }
    }
    .article__body {
      display: flex;
      flex-wrap: wrap-reverse;
      margin: 1em 1.25em;
    }
  }

  // Extra Type Options
  ${(props) => {
    if (props.styleType === "centered") {
      return css`
        padding-left: 0;
        nav ol {
          justify-content: center;
        }
        .article__content .content-hero__header > h1 {
          padding: 0.25em 1em;
          margin: 0;
          text-align: center;
        }
        .content-body__container {
          margin: 0 auto;
        }
      `;
    }
  }}
`;

export default Article;
